import React, { useEffect, useState } from "react";
import classes from "./auth.module.css";
import mainLogo from "../../Assets/Images/MainLogo.png";
import CommonImageContainer from "./CommonImageContainer";
import {
  Typography,
  Button,
  LinearProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import {
  Numeric,
  calculatePasswordStrength,
  getStrengthColor,
  getStrengthLabel,
} from "../../Utilities/commonFunctions";
import SuccessModal from "../../Components/successModal";
import { useDispatch, useSelector } from "react-redux";
import {
  VerifyOtp,
  forgotPasswordEmailsend,
  pageLoader,
  resetForgetPassword,
} from "../../Redux/actions/userActions";
import AlertBox from "../../Components/AlertBox";

const ForgotPasswordIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successModal, setSuccessModal] = useState(false);
  const [email, setEmail] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [createpasswordScreen, setCreatepasswordScreen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [title, setTitle] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [time, setTime] = useState(60);
  const [disable, setDisable] = useState({
    name: `Resend in ${time}s`,
    state: true,
  });
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, forgot_otp } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevCount) => prevCount - 1);
      setDisable({
        name: `Resend ${time - 1}s`,
        state: true,
      });
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      setDisable({
        name: "Resend Otp",
        state: false,
      });
    }
    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const handleEmailSubmit = (e) => {
    let title;
    if (/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/.test(email)) {
      title = "email";
      // setTitle(title);
    } else {
      title = "phone";
      // setTitle(title);
    }
    const userEntry = {
      username: email,
      title: title,
    };
    // console.log("this is what user entry look like", userEntry);
    dispatch(pageLoader(true));
    dispatch(forgotPasswordEmailsend(userEntry, setOtpScreen, setErrorAlert));
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleOtpSubmit = (e) => {
    const userEntry = {
      username: email,
      otp: otp,
      title: "forgot-password",
    };
    console.log(userEntry);
    dispatch(pageLoader(true));
    dispatch(
      VerifyOtp(userEntry, setOtpScreen, setCreatepasswordScreen, setErrorAlert)
    );
  };

  const handleSubmit = (e) => {
    const userEntry = {
      username: email,
      newPassword: newPassword,
    };
    console.log(userEntry);
    dispatch(pageLoader(true));
    dispatch(resetForgetPassword(userEntry, setSuccessModal, setErrorAlert));
    setSuccessModal(true);
  };

  const modalClose = () => {
    // console.log("coming here", successModal);
    setSuccessModal(false);
    // console.log("coming here", successModal);
    navigate("/login");
  };

  // const handleNewPasswordChange = (e) => {
  //   const newPasswordValue = e.target.value;
  //   setNewPassword(newPasswordValue);
  // };

  // const handleConfirmPasswordChange = (e) => {
  //   const confirmPasswordValue = e.target.value;
  //   setConfirmPassword(confirmPasswordValue);
  //   setPasswordsMatch(newPassword === confirmPasswordValue);
  // };
  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    // Update passwordsMatch immediately when new password changes
    const passwordsMatch = confirmPassword === newPasswordValue;
    setPasswordsMatch(passwordsMatch);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);

    // Check if the new password and confirm password match
    const passwordsMatch = newPassword === confirmPasswordValue;
    setPasswordsMatch(passwordsMatch);
  };

  return (
    <div className={classes.guestLayoutMainDiv}>
      <div className={classes.formContainerdiv}>
        <img src={mainLogo} alt="MainLogo" className={classes.mainLogo} />
        {!otpScreen && !createpasswordScreen && (
          <div className={classes.formContainer}>
            <Typography className={classes.welcomeHeadertext}>
              Forgot Password
            </Typography>
            <Typography className={`${classes.subHeaderText} mb-5`}>
              Please enter your registered email ID or Mobile number and we will
              send you a link to reset it.
            </Typography>
            <ValidatorForm
              onSubmit={handleEmailSubmit}
              useref="form"
              className={classes.LoginForm}
            >
              <div className="mb-3">
                <Typography className={`${classes.form_lable} mb-2`}>
                  Email ID / Mobile Number
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="emailidInput"
                  fullWidth
                  placeholder="Enter your email ID / Mobile number"
                  className={classes.TextInput}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  size="small"
                  validators={["required", "isEmailOrMobileNumber"]} // Add this line
                  errorMessages={[
                    "Email is required",
                    "Invalid email or mobile number",
                  ]}
                />
              </div>
              <div className={classes.flexEnd}>
                <Button
                  style={{
                    opacity: !email ? 0.5 : 1,
                  }}
                  disabled={!email}
                  variant="contained"
                  type="submit"
                  className={classes.loginButton}
                >
                  Submit
                </Button>
              </div>
            </ValidatorForm>
          </div>
        )}
        {otpScreen && !createpasswordScreen && (
          <div className={classes.formContainer}>
            <Typography className={classes.welcomeHeadertext}>
              Verify OTP
            </Typography>
            <Typography className={`${classes.subHeaderText}`}>
              Enter 6 Digit OTP Code send to your Email ID
            </Typography>
            <Typography className={`${classes.subHeaderText} mb-5`}>
              Otp:{forgot_otp}
            </Typography>
            <ValidatorForm
              onSubmit={handleOtpSubmit}
              useref="form"
              className={classes.LoginForm}
            >
              <div className="mb-3">
                <Typography className={`${classes.form_lable} mb-2`}>
                  OTP Code
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="otpInput"
                  fullWidth
                  placeholder="Enter OTP code"
                  className={classes.TextInput}
                  value={otp}
                  inputProps={{
                    maxLength: 4,
                  }}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Email is required"]}
                />
                <Typography className={`${classes.form_lable} mb-2`}>
                  Didn't Receive OTP?
                  <Button
                    disabled={disable.state}
                    className="fw-medium"
                    variant="text"
                    sx={{
                      "&.Mui-disabled": {
                        color: "#0000ff73 !important",
                      },
                    }}
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      textTransform: "none",
                      border: "none",
                    }}
                    onClick={() => handleEmailSubmit()}
                  >
                    {disable.name}
                  </Button>
                </Typography>
              </div>
              <div className={classes.flexEnd}>
                <Button
                  style={{
                    opacity: !otp || otp.length < 4 ? 0.5 : 1,
                  }}
                  variant="contained"
                  type="submit"
                  disabled={!otp || otp.length < 4}
                  className={classes.loginButton}
                >
                  Submit
                </Button>
              </div>
            </ValidatorForm>
          </div>
        )}
        {!otpScreen && createpasswordScreen && (
          <div className={classes.formContainer}>
            <Typography className={classes.welcomeHeadertext}>
              Create New Password
            </Typography>
            <Typography className={`${classes.subHeaderText} mb-5`}>
              Your password must minimum of 8 characters, with upper and
              lowercase and a number or a symbol{" "}
            </Typography>
            <ValidatorForm
              onSubmit={handleSubmit}
              useref="form"
              className={classes.LoginForm}
            >
              <div className={`${classes.passwordInputContainer} mb-3`}>
                <Typography className={`${classes.form_lable} mb-2`}>
                  New Password
                </Typography>
                <div>
                  <div>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="outlined-adornment-password"
                      type={showNewPassword ? "text" : "password"}
                      fullWidth
                      placeholder="Enter new password"
                      className={classes.TextInput}
                      size="small"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                      validators={["required"]}
                      errorMessages={["New password is required"]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                              edge="end"
                            >
                              {showNewPassword ? (
                                <CloseEyeIcon />
                              ) : (
                                <OpenEyeIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <div className={classes.showPassword}>
                                            {showPassword ? (
                                                <Button
                                                    variant="text"
                                                    onClick={() => setShowPassword(false)}
                                                >
                                                    <OpenEyeIcon />
                                                </Button>
                                            ) : (
                                                <Button onClick={() => setShowPassword(true)}>
                                                    <CloseEyeIcon />
                                                </Button>
                                            )}
                                        </div> */}
                  </div>
                </div>
                {newPassword && newPassword.length > 0 && (
                  <div className={classes.displayflex}>
                    <LinearProgress
                      variant="determinate"
                      value={calculatePasswordStrength(newPassword)}
                      sx={{
                        backgroundColor:
                          "var(--progressbar-default-background-color)",
                        width: "90%",
                        borderRadius: "100px",
                      }}
                      className={`${
                        classes.custom_progress_bar
                      } ${getStrengthColor(
                        calculatePasswordStrength(newPassword)
                      )} `}
                    />
                    <span className={classes.passwordStrongText}>
                      {getStrengthLabel(calculatePasswordStrength(newPassword))}
                    </span>
                  </div>
                )}
              </div>
              <div className={`${classes.passwordInputContainer} mb-3`}>
                <Typography className={`${classes.form_lable} mb-2`}>
                  Confirm Password
                </Typography>
                <div className="mb-3">
                  <div>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      fullWidth
                      placeholder="Enter confirm password"
                      className={classes.TextInput}
                      size="small"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      validators={["required"]}
                      errorMessages={["Confirm password is required"]}
                      error={!passwordsMatch} // Set an error state if passwords don't match
                      // helperText={!passwordsMatch && "Passwords do not match"}
                      helperText={
                        !passwordsMatch ? "Passwords do not match" : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <CloseEyeIcon />
                              ) : (
                                <OpenEyeIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <div className={classes.showPassword}>
                                            {showPassword ? (
                                                <Button
                                                    variant="text"
                                                    onClick={() => setShowPassword(false)}
                                                >
                                                    <OpenEyeIcon />
                                                </Button>
                                            ) : (
                                                <Button onClick={() => setShowPassword(true)}>
                                                    <CloseEyeIcon />
                                                </Button>
                                            )}
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className={classes.flexEnd}>
                <Button
                  variant="contained"
                  style={{
                    opacity:
                      !newPassword ||
                      !passwordsMatch ||
                      !confirmPassword ||
                      confirmPassword.length < 8 ||
                      newPassword.length < 8
                        ? 0.5
                        : 1,
                  }}
                  type="submit"
                  disabled={
                    !newPassword ||
                    !passwordsMatch ||
                    !confirmPassword ||
                    confirmPassword.length < 8 ||
                    newPassword.length < 8
                  }
                  className={classes.loginButton}
                >
                  Create
                </Button>
              </div>
            </ValidatorForm>
          </div>
        )}
      </div>
      <div className={classes.imageContainerParent}>
        <CommonImageContainer />
      </div>
      {successModal ? (
        <SuccessModal
          modalClose={modalClose}
          width={335}
          height={270}
          type={"forgotPassword"}
        />
      ) : null}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default ForgotPasswordIndex;
