import {
  FORGOT_PASSWORD_OTP,
  HEADER_NAME,
  LOGGEDIN_USER,
  PAGE_LOADER,
  PAGINATION_VALUE,
  PAYMENT_FAIL,
  PAYMENT_SUCCESS,
  SORT_BY_VALUE,
  TOGGLE_SIDEBAR,
  USER_CHANGE_PASSWORD_FAIL,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
} from "../contants/userConstants";

const initialState = {
  loading: false,
  userInfo: null,
  loggedUser: null,
  userRole: null,
  error: null,
  invoice: null,
  forgot_otp: null,
  success: null,
  headerName: "dashboard",
  toggleSideBar: false,
  showError: false,
  sortBy: null,
  page: 1,
};

export const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOADER:
      return { ...state, loading: action.payload };
    case USER_LOGIN_SUCCESS:
      return { ...state, loggedUser: action.payload, userRole: action.payload.role, error: null };
    case USER_LOGIN_FAIL:
      return { ...state, error: action.payload };
    case HEADER_NAME:
      return { ...state, headerName: action.payload };
    case TOGGLE_SIDEBAR:
      return { ...state, toggleSideBar: action.payload };
    case USER_RESET_PASSWORD_FAIL:
      return { ...state, error: action.payload };
    case USER_RESET_PASSWORD_SUCCESS:
      return { ...state, error: null, success: action.payload };
    case SORT_BY_VALUE:
      return { ...state, sortBy: action.payload };
    case PAGINATION_VALUE:
      return { ...state, page: action.payload };
    case PAYMENT_SUCCESS:
      return { ...state, error: null, invoice: action.payload };
    case PAYMENT_FAIL:
      return { ...state, error: action.payload };
    case FORGOT_PASSWORD_OTP:
      return {
        ...state, forgot_otp: action.payload
      };
    case LOGGEDIN_USER:
      return {
        ...state, userInfo: action.payload.user
      };
    case USER_CHANGE_PASSWORD_SUCCESS:
      return { ...state, error: null, success: action.payload };
    case USER_CHANGE_PASSWORD_FAIL:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};
