import React from "react";
import classes from "./layout.module.css";
import mainLogo from "../Assets/Images/MainLogo.png";
import { Button } from "@mui/material";
import { CloseIcon } from "../Assets/Icons/icons";
import { useNavigate } from "react-router-dom/dist";

const FunctionalHeader = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (window.location.pathname.includes("invoice")) {
      navigate("/dashboard");
    } else {
      navigate(-1);
    }
  };
  return (
    <div>
      <img
        src={mainLogo}
        alt="Queens Award Logo"
        className={`${classes.functionalImageLogo} ${classes.sidenavLogoImage}`}
      />
      <Button
        className={classes.functionalCloseButton}
        onClick={handleNavigate}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

export default FunctionalHeader;
