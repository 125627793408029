import React, { useEffect, useState } from "react";
import classes from "./auth.module.css";
import mainLogo from "../../Assets/Images/MainLogo.png";
import CommonImageContainer from "./CommonImageContainer";
import { Typography, Button } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, pageLoader } from "../../Redux/actions/userActions";
import AlertBox from "../../Components/AlertBox";

ValidatorForm.addValidationRule("isEmailOrMobileNumber", (value) => {
  // You can use regular expressions to check for email and mobile number formats
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const mobileNumberRegex = /^\d{10}$/; // Assuming a 10-digit mobile number format

  if (emailRegex.test(value) || mobileNumberRegex.test(value)) {
    return true; // The input is valid
  }

  return false; // The input is invalid
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const { userInfo, error } = useSelector((state) => state.userLogin);

  const handleSubmit = async (e) => {
    const userEntry = {
      username: email,
      password: password,
    };
    console.log(userEntry);
    dispatch(pageLoader(true));
    dispatch(loginUser(userEntry, navigate, errorAlert, setErrorAlert));
    // navigate("/dashboard");
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  return (
    <div className={classes.guestLayoutMainDiv}>
      <div className={classes.formContainerdiv}>
        <img src={mainLogo} alt="MainLogo" className={classes.mainLogo} />
        <div className={classes.formContainer}>
          <Typography className={classes.welcomeHeadertext}>
            Welcome to Queens Award
          </Typography>
          <Typography className={`${classes.subHeaderText} mb-5`}>
            Please enter your email ID and Password to login
          </Typography>
          <ValidatorForm
            onSubmit={handleSubmit}
            useref="form"
            className={classes.LoginForm}
          >
            <div className="mb-3">
              <Typography className={`${classes.form_lable} mb-2`}>
                Email ID / Mobile Number
              </Typography>
              <TextValidator
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "15px",
                }}
                id="emailidInput"
                fullWidth
                placeholder="Enter your email ID / Mobile number"
                className={classes.TextInput}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                size="small"
                validators={["required", "isEmailOrMobileNumber"]} // Add this line
                errorMessages={[
                  "Email is required",
                  "Invalid email or mobile number",
                ]}
              />
            </div>
            <div className={`${classes.passwordInputContainer} mb-3`}>
              <div className={classes.displayflex}>
                <Typography className={`${classes.form_lable} mb-2`}>
                  Password
                </Typography>
                <Button
                  variant="text"
                  className={`${classes.forgotPassword}`}
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot Password?
                </Button>
              </div>
              <div className="mb-3">
                <div>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    placeholder="Enter Password"
                    className={classes.TextInput}
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                  />
                  <div className={classes.showPassword}>
                    {showPassword ? (
                      <Button
                        variant="text"
                        onClick={() => setShowPassword(false)}
                      >
                        <OpenEyeIcon />
                      </Button>
                    ) : (
                      <Button onClick={() => setShowPassword(true)}>
                        <CloseEyeIcon />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.flexEnd}>
              <Button
                variant="contained"
                style={{
                  opacity: !email || !password || password.length < 8 ? 0.5 : 1,
                }}
                type="submit"
                className={classes.loginButton}
                disabled={!email || !password}
              >
                Login
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
      <div className={classes.imageContainerParent}>
        <CommonImageContainer />
      </div>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default Login;
