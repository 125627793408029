import React, { useEffect } from "react";
import classes from "./teacher.module.css";
import {
  BrudCrumsNextIcon,
  CloseEyeIcon,
  ContentCopyIcon,
  OpenEyeIcon,
  RefreshIcon,
} from "../../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { Card, Typography, MenuItem, Button } from "@mui/material";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Alphabetic,
  Numeric,
  generatePassword,
} from "../../../Utilities/commonFunctions";
import Footer from "../../../Components/Footer";
import copy from "copy-to-clipboard";
import { getAllClasses } from "../../../Redux/actions/classActions";
import AlertBox from "../../../Components/AlertBox";
import {
  addTeacher,
  getAllDesignations,
  getOneTeacher,
  getSchoolRoles,
  updateTeacher,
} from "../../../Redux/actions/teacherActions";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';

const AddTeacher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [designationError, setDesignationError] = useState(false);
  const [accountEmailError, setAccountEmailError] = useState(true);
  const [experienceError, setExperienceError] = useState(false);
  const [gradeError, setGradeError] = useState(false);
  const [school, setSchool] = useState("select");
  const [teacherName, setTeacherName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [designation, setDesignation] = useState("select");
  const [noOfExperiance, setNoOfExperiance] = useState("select");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [grade, setGrade] = useState("select");
  const [generateClicked, setGenerateClicked] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [defaultSuccessAlert, setDefaultSuccessAlert] = useState(false);
  const [defaultErrorAlert, setDefaultErrorAlert] = useState(false);
  const [defaultError, setDefaultError] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [defaultSuccess, setDefaultSuccess] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const { classesList } = useSelector((state) => state.classDatas);
  const { error, success, designations, oneTeacher, schoolRoles } = useSelector(
    (state) => state.teacherDatas
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userInfo) {
      dispatch(pageLoader(true));
      dispatch(getAllClasses(setErrorAlert, "", userInfo?.schoolInfo?._id));
      setSchool(userInfo?.schoolInfo?._id);
      dispatch(getSchoolRoles(userInfo?.schoolInfo?._id, setErrorAlert));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      const path = window.location.pathname.split("/");
      dispatch(getOneTeacher(path[path.length - 1], setErrorAlert));
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("edit") && userInfo) {
      setSchool(userInfo?.schoolInfo?._id);
      setTeacherName(oneTeacher?.name);
      setAccountEmail(oneTeacher?.userInfo?.email);
      setDesignation(oneTeacher?.designation);
      // setNoOfExperiance(oneTeacher?.experience);
      setEmail(oneTeacher?.userInfo?.email);
      setPhone(oneTeacher?.userInfo?.phone);
      setGrade(oneTeacher?.class);
      setCountryCode(oneTeacher?.userInfo?.country_code);
      setSelectedCountry(oneTeacher?.userInfo?.country_id);
    }
  }, [oneTeacher, userInfo]);

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
    setDefaultErrorAlert(false);
    setDefaultSuccessAlert(false);
  };

  const copyTrst = (text) => {
    copy(text);
    setDefaultSuccessAlert(true);
    setDefaultSuccess("Password has Copied Successfully");
  };

  const handleNavigateTeacher = () => {
    dispatch(setHeaderName("teachers"));
    navigate("/teachers");
  };

  const handleGeneratePassword = () => {
    generatePassword(teacherName, setPassword);
  };

  const handleSubmit = () => {
    // if (phone?.length != 10) {
    //   setIsValid(false);
    // } else 
    if (!isValid) {
      setIsValid(false);
    }
    else if (grade === "select") {
      setGradeError(true);
    }
    else if (designation === "select") {
      setDesignationError(true);
    }
    // else if (noOfExperiance === "select") {
    //   setExperienceError(true);
    // }
    else if (
      designation !== "select" &&
      grade !== "select"
    ) {
      const userEntry = {
        name: teacherName,
        email: email,
        designation: designation,
        // experience: noOfExperiance,
        phone: phone,
        class: grade,
        school: school,
        country_code: countryCode,
        country_id: selectedCountry,
      };
      dispatch(pageLoader(true));
      if (window.location.pathname.includes("edit")) {
        dispatch(
          updateTeacher(
            oneTeacher._id,
            userEntry,
            setSuccessAlert,
            setErrorAlert
          )
        );
      } else {
        userEntry.password = password;
        dispatch(addTeacher(userEntry, setSuccessAlert, setErrorAlert));
      }
    }
  };

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData.iso2);
    setPhone("");
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setPhone(value);
  };

  const isEqual = (value1, value2) => {
    return value1 === value2;
  };

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigateTeacher()}
        >
          Teacher
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
          Add Teacher
        </span>
      </div>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={classes.AddStudentForm}
      >
        <Card className={`mt-3`} style={{ padding: "20px", textAlign: "left" }}>
          <Typography className={classes.MainHeading1}>
            Teacher Details
          </Typography>
          <Row className="mt-3">
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Teacher Name
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="teachernameInput"
                  fullWidth
                  placeholder="Enter Teacher name"
                  className={classes.TextInput}
                  value={teacherName}
                  onChange={(e) => {
                    setTeacherName(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    Alphabetic(e);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Teacher Name is required"]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Designation
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  select
                  id="teachernameInput"
                  fullWidth
                  className={
                    designation === "select"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  value={designation}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                    if (e.target.value !== "select") {
                      setDesignationError(false);
                    }
                  }}
                  size="small"
                  error={designationError}
                  helperText={designationError && "Designation is required"}
                >
                  <MenuItem value="select" style={{ display: "none" }}>
                    Enter Designation
                  </MenuItem>
                  {schoolRoles &&
                    schoolRoles.length > 0 &&
                    schoolRoles
                      .filter((item) => item.isActive === true)
                      .map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item._id}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item?.designationInfo?.name
                              ?.charAt(0)
                              .toUpperCase() +
                              item?.designationInfo?.name
                                ?.slice(1)
                                .toLowerCase()
                                .replaceAll("_", ".")}
                          </MenuItem>
                        );
                      })}
                </TextValidator>
              </div>
            </Col>
            {/* <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Years of Experience 
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  select
                  id="teachernameInput"
                  fullWidth
                  className={
                    noOfExperiance === "select"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  value={noOfExperiance}
                  onChange={(e) => {
                    setNoOfExperiance(e.target.value);
                    if (e.target.value !== "select") {
                      setExperienceError(false);
                    }
                  }}
                  size="small"
                  error={experienceError}
                  helperText={
                    experienceError && "Years of experience is required"
                  }
                >
                  <MenuItem value="select" style={{ display: "none" }}>
                    Select years of experience
                  </MenuItem>
                  {["1", "2", "3", "4", "5", "6", "7", "8", "9", `9+`].map(
                    (item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    }
                  )}
                </TextValidator>
              </div>
            </Col> */}
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Email ID
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="emailInput"
                  fullWidth
                  placeholder="Enter email ID"
                  className={classes.TextInput}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value !== accountEmail) {
                      setGenerateClicked(false);
                      setPassword("");
                    }
                  }}
                  size="small"
                  validators={["required", "isEmail"]} // Add this line
                  errorMessages={["Email is required", "Email is not valid"]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Contact Number
                </Typography>
                {/* <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="mobileInput"
                  fullWidth
                  placeholder="Enter contact number"
                  className={classes.TextInput}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  inputProps={{ maxLength: 10 }}
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  size="small"
                  validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                  errorMessages={[
                    "Contact Number is required",
                    "Enter a valid contact number",
                  ]}
                /> */}
                 <div className={classes.new_countrycode_field} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={phone}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Grade
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  select
                  id="gradeInput"
                  fullWidth
                  placeholder="Select grade"
                  className={
                    grade === "select"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  value={grade}
                  onChange={(e) => {
                    setGrade(e.target.value);
                    if (e.target.value !== "select") {
                      setGradeError(false);
                    }
                  }}
                  size="small"
                  error={gradeError}
                  helperText={gradeError && "Grade is required"}
                >
                  <MenuItem
                    key={"default"}
                    value={"select"}
                    style={{ textTransform: "capitalize", display: "none" }}
                  >
                    Select Grade
                  </MenuItem>
                  {classesList &&
                    classesList.length > 0 &&
                    classesList.map((item, index) => {
                      const isActive = item.terminology.some(
                        (section) => section.isActive
                      );
                      if (isActive) {
                        return (
                          <MenuItem
                            key={index}
                            value={item._id}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.grade?.charAt(0).toUpperCase() +
                              item.grade
                                ?.slice(1)
                                .toLowerCase()
                                .replaceAll("_", " ")}
                          </MenuItem>
                        );
                      }
                    })}
                </TextValidator>
              </div>
            </Col>
          </Row>
        </Card>
        <Card
          className={`mt-3`}
          style={{ padding: "20px", textAlign: "left", marginBottom: "120px" }}
        >
          <Typography className={classes.StudentPageMainHeading}>
            Account Details
          </Typography>
          <Row className="mt-3">
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Account Email ID
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="accountEmailInput"
                  fullWidth
                  placeholder="Enter account email ID"
                  className={classes.TextInput}
                  value={accountEmail}
                  onChange={(e) => {
                    setAccountEmail(e.target.value);
                    setAccountEmailError(email === e.target.value);
                    if (e.target.value !== email) {
                      setGenerateClicked(false);
                      setPassword("");
                    }
                  }}
                  size="small"
                  validators={["required", "isEmail"]}
                  errorMessages={["Email is required", "Email is not valid"]}
                  error={!accountEmailError}
                  helperText={
                    !accountEmailError
                      ? "Account Email ID does not match with Email ID"
                      : ""
                  }
                />
              </div>
            </Col>
            {window.location.pathname.includes("edit") ? (
              <Col md={4}>
                <div style={{ position: "relative" }}>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
                  >
                    Password
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    placeholder="Enter Password"
                    className={classes.TextInput}
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className={classes.showPassword}>
                    {showPassword ? (
                      <Button
                        variant="text"
                        style={{ minWidth: "unset" }}
                        onClick={() => setShowPassword(false)}
                      >
                        <OpenEyeIcon />
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        style={{ minWidth: "unset" }}
                        onClick={() => setShowPassword(true)}
                      >
                        <CloseEyeIcon />
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            ) : (
              <Col md={3}>
                <Button
                  className={
                    email !== accountEmail ||
                    email === "" ||
                    accountEmail === ""
                      ? `mt-3 ${classes.PassgenerateFunction} ${classes.disabledBtn}`
                      : `mt-3 ${classes.PassgenerateFunction}`
                  }
                  disabled={
                    email !== accountEmail ||
                    email === "" ||
                    accountEmail === ""
                  }
                  variant="contained"
                  onClick={() => {
                    setGenerateClicked(true);
                    generatePassword(teacherName, setPassword);
                  }}
                >
                  Generate Password
                </Button>
              </Col>
            )}
          </Row>
          {generateClicked && email === accountEmail ? (
            <div>
              <Typography className={`mt-3 ${classes.passwordSentence}`}>
                Queens Award create a uniq password for you. Once you are done,
                Click the copy button
              </Typography>
              <Row className="mt-2">
                <Col xs={10} md={4} style={{ position: "relative" }}>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
                  >
                    Password
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="outlined-adornment-password"
                    type={"text"}
                    fullWidth
                    placeholder="Enter Password"
                    className={classes.TextInput}
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                  />
                  <div className={classes.refreshIcon}>
                    <Button
                      variant="text"
                      onClick={() => generatePassword(teacherName, setPassword)}
                    >
                      <RefreshIcon />
                    </Button>
                  </div>
                  <div className={classes.copyIcon}>
                    <Button
                      variant="text"
                      onClick={() => {
                        copyTrst(password);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Typography className={classes.NoteHeader}>
                Note:{" "}
                <span className={classes.Notetext}>
                  Once you click on submit your access ID and password send to
                  the parent email ID
                </span>
              </Typography>
            </div>
          ) : null}
        </Card>
        <Footer
          callback={handleNavigateTeacher}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {defaultErrorAlert && defaultError && (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateTeacher}
        />
      )}
      {defaultSuccessAlert && defaultSuccess && (
        <AlertBox
          type="success"
          message={defaultSuccess}
          stateName={CloseAlert}
        />
      )}
    </div>
  );
};

export default AddTeacher;
