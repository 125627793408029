import React, { useEffect, useState } from "react";
import classes from "./layout.module.css";
import { Avatar, Button, Divider, MenuItem } from "@mui/material";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  BlueLogoutIcon,
  ChangePasswordIcon,
  CloseIcon,
  LogoutIcon,
  MenuBarIcons,
  NotificationBellIcon,
  ProfileExpandMoreIcon,
  UserIcon,
} from "../Assets/Icons/icons";
import { Typography } from "@mui/material";
import mainLogo from "../Assets/Images/MainLogo.png";
import SideNavBar from "./SideNavBar";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName, setToggleSidebar } from "../Redux/actions/userActions";
import Profile from "../Assets/Images/Ellipse 6128.png";
import { StyledMenu } from "../Components/CommonMenu";
import SuccessModal from "../Components/successModal";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { headerName, toggleSideBar, userRole, userInfo } = useSelector(
    (state) => state.userLogin
  );

  const handleClose = () => setShow(false);

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleShow = () => {
    dispatch(setToggleSidebar(!toggleSideBar));
    setShow(true);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    if (arr[1] === "cources") {
      dispatch(setHeaderName("Courses/Games"));
    } else {
      dispatch(setHeaderName(arr[1]));
    }
  }, [window.location.pathname]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const modalClose = () => {
    setLogoutModal(false);
  };

  const handleClickMyProfile = (e) => {
    handleMenuClose(e);
    dispatch(setHeaderName("Profile"));
    navigate("/profile");
  };

  const handleClickChangePassword = (e) => {
    handleMenuClose(e);
    dispatch(setHeaderName("Change Password"));
    navigate("/change-password");
  };

  const handleClickLogout = (e) => {
    handleMenuClose(e);
    setLogoutModal(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/login");
  };

  return (
    <div>
      <div className={classes.HeaderContainer}>
        <div className={classes.HeaderMaindiv}>
          <Button variant="text" onClick={handleShow} style={{ minWidth: 0 }}>
            <MenuBarIcons />
          </Button>
          <Offcanvas
            show={show}
            onHide={handleClose}
            className={
              userRole === "TEACHER"
                ? classes.offcanvas
                : classes.schoolOffcanvas
            }
            scroll={false}
            backdrop={false}
          >
            <Offcanvas.Header>
              <Offcanvas.Title>
                <img
                  src={mainLogo}
                  alt="Queens Award Logo"
                  className={`${classes.imageLogo}`}
                />
                <Button
                  variant="text"
                  className={
                    userRole === "TEACHER"
                      ? `${classes.offcanvaCloseButton} ${classes.teacherCloseButton}`
                      : `${classes.offcanvaCloseButton}`
                  }
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body
              style={{ padding: 0 }}
              className={classes.offcanvasBody}
            >
              <SideNavBar device={"mobile"} handleClose={handleClose} />
            </Offcanvas.Body>
          </Offcanvas>
          <Typography className={classes.HeaderText}>
            {headerName?.replaceAll("-", " ")}
          </Typography>
        </div>
        {userRole === "SCHOOL" && (
          <div className={classes.HeaderProfileContainer}>
            <span>
              <NotificationBellIcon />
            </span>
            <Divider
              orientation="vertical"
              className={classes.headerProfileDivider}
            />
            <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                variant="text"
                style={{
                  minWidth: 0,
                  padding: 0,
                }}
                className={classes.HeaderProfileContainer}
              >
                <Avatar sx={{ width: 40, height: 40 }} src={Profile} />
                <Typography className={classes.HeaderProfileName}>
                  Admin
                </Typography>
                <span>
                  <ProfileExpandMoreIcon />
                </span>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={handleClickMyProfile}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <UserIcon />
                  <span className={classes.MenuItemText}>My Profile</span>
                </MenuItem>
                <MenuItem
                  onClick={handleClickChangePassword}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <ChangePasswordIcon />
                  <span className={classes.MenuItemText}>Change Password</span>
                </MenuItem>
                <MenuItem
                  onClick={handleClickLogout}
                  disableRipple
                  style={{ height: "40px" }}
                >
                  <BlueLogoutIcon />
                  <span className={classes.MenuItemText}>Logout</span>
                </MenuItem>
              </StyledMenu>
            </div>
          </div>
        )}
      </div>
      {logoutModal && (
        <SuccessModal
          modalClose={modalClose}
          width={335}
          height={153}
          type={"logout"}
          callback={handleLogout}
        />
      )}
    </div>
  );
};

export default Header;
